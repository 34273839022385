<template>
    <div class="forgot">
        <headerLayout class="h" :no_bg="true"/>

        <div class="main" v-if="!sent">
            <div class="title">Forgot Password?</div>
            <div class="small">Enter the email address you registered with Bigglandlord</div>
            
            <div class="input">
                <div class="label" v-if="email != ''">Email:</div>
                <input type="text" placeholder="Email" v-model="email">
                <div class="err" v-if="e">Email has not been registered</div>
            </div>

            <loader :height="'37px'" v-if="load1"/>
            <div class="btn" v-else @click="send_otp()">Get OTP</div>
        </div>

        <div class="main" v-else>
            <div class="title">Verify Email</div>
            <div class="small">Enter the code entered to your email address and your new password</div>
            
            <div class="input">
                <div class="label" v-if="code != ''">Verification Code:</div>
                <input type="text" placeholder="Verification Code" v-model="code">
                <div class="err" v-if="v">Incorrect Verification Code</div>
            </div>
            
            <div class="input">
                <div class="label" v-if="password != ''">New Password:</div>
                <div class="flex">
                    <input :type="show ? 'text' : 'password'" placeholder="New Password" v-model="password">
                    <div class="view" @click="show = !show">
                        <eye :size="'20px'"/>
                    </div>
                </div>
            </div>
            
            <div class="input">
                <div class="label" v-if="password2 != ''">Repeat Password:</div>
                <div class="flex">
                    <input :type="show ? 'text' : 'password'" placeholder="Repeat Password" v-model="password2">
                    <div class="view" @click="show = !show">
                        <eye :size="'20px'"/>
                    </div>
                </div>
            </div>

            <loader :height="'37px'" v-if="load2"/>
            <div class="btn" v-else @click="submit()">Submit</div>
        </div>

        <footerLayout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import footerLayout from '../components/footer.vue'
import eye from '../icons/view.vue'
import loader from '../components/loader.vue'

export default {
    components: {
        headerLayout,
        footerLayout,
        eye, loader
    },
    data() {
        return {
            email: '',
            load1: false,
            load2: false,

            code: '',
            password: '',
            password2: '',
            e: false,
            v: false,
            sent: false,
            show: true
        }
    },
    methods: {
        send_otp() {
            if (this.email == '') { this.emter_email(); return }

            this.load1 = true

            this.e = false
            let form = new FormData()
            form.append('email', this.email)

            this.$http.post('/forgot_pass', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.sent = true
                } else {
                    if (r.e) {
                        this.e = true
                    }
                }
                this.load1 = false
            })
        },
        submit() {
            if (this.code == '' || this.password == '' || this.password2 == '') {
                this.enter(); return 
            }
            if (this.password !== this.password2) {
                this.password_mismatch(); return 
            }

            this.load2 = true
            this.v = false

            let form = new FormData()
            form.append('email', this.email)
            form.append('code', this.code)
            form.append('password', this.password)
            form.append('password2', this.password2)

            this.$http.post('/change_pass', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 3500);
                } else {
                    if (r.v) {
                        this.v = true
                    }
                }
                this.load2 = false
            })
        }
    },
    notifications: {
        emter_email: {
            type: 'warn',
            title: 'Email',
            message: 'Enter your email address'
        },
        enter: {
            type: 'warn',
            title: '',
            message: 'Please enter verification code and your new password'
        },
        password_mismatch: {
            type: 'warn',
            title: 'Password Mismatch',
            message: 'Please check your password'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your password has been change, you can now login'
        }
    }

}
</script>

<style scoped>

    .main {
        max-width: 350px;
        margin: 150px auto;
        margin-top: 230px;
        padding: 30px;
        background-color: #edeef0;
        border-radius: 10px;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
    }
    .small {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
        margin-bottom: 30px;
        margin-top: 5px;
    }
    
    .flex {
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 5px;
    }
    .view {
        padding: 0px 10px;
        fill: var(--main);
        cursor: pointer;
    }
    
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -20px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: none;
        box-sizing: border-box;
        outline: none;
        background-color: white;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 8px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }
</style>